import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const teamStyle = {
  mrAuto,
  mlAuto,
  title: {
    ... title,
    "@media (max-width: 830px)": {
      fontSize: "1.7rem",
    }
  },
  description: {
    ...description,
    color: grayColor[4],
    marginBottom: "80px",
    "@media (max-width: 830px)": {
      marginBottom: "50px",
    }
  },
  cardTitle: {
    "&, & a": {
      ...title,
      fontWeight: "400",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      marginTop: ".625rem",
      marginBottom: "0rem",
      minHeight: "auto",
    }
  },
  cardDescription: {
    fontWeight: "400",
    color: grayColor[7]
  },
  team: {
    padding: "80px 0px",

    "@media (max-width: 830px)": {
      paddingTop: "10px",
    }
  },
  textCenter: {
    textAlign: "center!important"
  },
  img: {
    width: "100%",
    height: "auto",
  },
  imgContainer: {
    width: "100%",
    height: "auto",
    textAlign: "center",
    backgroundSize: "90% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    
    "& img": {
      width: "70%"
    },

    "@media (max-width: 830px)": {
      "& img": {
        width: "100%"
      },
    }
  },
  imgContainer2: {
    width: "100%",
    height: "auto",
    textAlign: "center",
    backgroundSize: "90% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    
    "& img": {
      width: "50%"
    },

    "@media (max-width: 830px)": {
      "& img": {
        width: "100%"
      },
    }
  },
  textMuted: {
    color: grayColor[7] + " !important"
  },
  justifyContent: {
    justifyContent: "center!important"
  }
};

export default teamStyle;
