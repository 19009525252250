import {
  container,
  title,
  cardTitle,
  description,
  grayColor,
  sectionDark,
  colors,
  coloredShadow
} from "assets/jss/material-kit-pro-react.js";

const sectionSimilarStoriesStyle = {
  container,
  cardTitle,
  sectionDark,
  coloredShadow,
  title: {
    ...title,
    "@media (max-width: 830px)": {
      fontSize: "1.7rem",
    }
  },
  textCenter: {
    textAlign: "center"
  },
  sectionDark: {
    ...sectionDark,
    backgroundColor: grayColor[3],
    padding: "70px 0 30px 0",
  },
  sectionGray: {
    // background: grayColor[14],
    backgroundImage: `linear-gradient(180deg, ${colors.grey[50]} 50%, #ffffff 0%)`,
    padding: "70px 0 30px 0",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",

    "@media (max-width: 830px)": {
      paddingTop: "0px",
    }
  },
  description1: {
    // color: grayColor[0]
    ...description,
    color: grayColor[4],
  },
  description2: {
    ...description,
    color: grayColor[4],
    lineHeight: "1.813rem"
  },
};

export default sectionSimilarStoriesStyle;
