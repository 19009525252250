/*eslint-disable*/
import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import { colors } from '@material-ui/core';
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionConsulting from "./Sections/SectionConsulting.js";
import SectionGetInTouch from "./Sections/SectionGetInTouch.js";
import SectionPrograms from "./Sections/SectionPrograms.js";
import SectionPricing from "./Sections/SectionPricing.js";

import ourServicesPageStyle from "assets/jss/material-kit-pro-react/views/ourServicesPageStyle.js";
import SectionJoinUsVirtually from "./Sections/SectionJoinUsVirtually.js";

const useStyles = makeStyles(ourServicesPageStyle);

const useStyles2 = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionAlternate: {
    backgroundImage: `linear-gradient(180deg, ${colors.grey[50]} 50%, #ffffff 0%)`,
  },
  
}));


let locationKey = ""

export default function OurServicesPage(rest) {
  const [headerLinksColor, setHeaderLinksColor] = React.useState("black");

  React.useEffect(() => {
    if (rest && rest.location.key !== locationKey) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;

      locationKey = rest.location.key;
    }
  });
  const classes = useStyles();
  const classes2 = useStyles2();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const macProHeight = useMediaQuery('(max-height: 830px)');
  const xlMonitor = useMediaQuery('(min-width: 1920px)');

  const Heading = ({level, children, ...props}) => {
    return React.createElement(`h${level}`, props , children)
  }

  const changeHeaderLinksColor = (color) => {
    setHeaderLinksColor(color);
  }

  return (
    <div>
      <Header
        brand="Insight Academix"
        links={<HeaderLinks dropdownHoverColor="info" color={headerLinksColor} />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info",
          changeHeaderLinksColor: changeHeaderLinksColor,
        }}
      />
      <Parallax image={require("assets/img/our-service/sub_ourservices_banner.jpg")} filter="dark" opacity={0} small={isSm ? false : true}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10} 
              className={classNames(
                // classes.mlAuto,
                // classes.mrAuto,
                classes.textCenter
              )}
              // className={classes.textCenter}
            >
              <h1 className={classes.title}>
                Our Services
              </h1>
              <Heading level={isSm ? 4 : 3} className={classes.subtitle}>
                We are committed to providing results for our students.
                We are a team of trained educational professionals who will work with students to reach their highest goals. 
                We boost your profile from the bottom up, building a personalized academic and extracurricular plan, 
                as well as resume-boosting opportunities such as national competitions, prestigious summer programs, 
                and mentored research projects.
              </Heading>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {(isSm || !macProHeight) && (
                <>
                  <br />
                  <br />
                </>
              )}
              { xlMonitor && (
                <>
                  <br />
                  <br />
                  <br />
                </>
              )}

            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        
          <SectionConsulting id="blogs" className={classes2.sectionAlternate} />
          <SectionPrograms />
          <SectionJoinUsVirtually />
        <div className={classes.container}>
          <SectionGetInTouch />
        </div>
      </div>
      
      <SectionPricing />
      
      <Footer theme="dark"
        content={
          <div>
          </div>
        }
      >
        <div></div>
      </Footer>
    </div>
  );
}
