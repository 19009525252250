import {
  container,
  title,
  mlAuto,
  mrAuto,
  description,
  colors,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const styles = {
  sectionGray: {
    // background: grayColor[14],
    backgroundImage: `linear-gradient(180deg, ${colors.grey[50]} 70%, #ffffff 0%)`,
    padding: "70px 0 30px 0",
  },
  container: {
    ...container,
    height: "350px",
    "@media (max-width: 830px)": {
      height: "200px",
    }
  },
  title: {
    ...title,
    "@media (max-width: 830px)": {
      fontSize: "1.7rem",
    }
  },
  mrAuto,
  mlAuto,
  description: {
    ...description,
    color: grayColor[4],
    "@media (max-width: 830px)": {
      textAlign: "left",
    }
  },
  
  textCenter: {
    padding: "0 20px",
    textAlign: "center !important"
  },
  white: {
    backgroundColor: whiteColor
  },
  dark: {
    background:
      "radial-gradient(ellipse at center," +
      grayColor[4] +
      " 0," +
      grayColor[5] +
      " 100%)",
    backgroundSize: "550% 450%",
    "& $border": {
      borderColor: "rgba(" + hexToRgb(whiteColor) + ",0.1)"
    }
  },
  
  subscribeLine: {
    padding: "1.875rem 0px",
    
    "&$subscribeLineImage:after": {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      height: "100%",
      display: "block",
      left: 0,
      top: 0,
      content: "''",
      // backgroundColor: "rgba(" + hexToRgb(blackColor) + ",0.16)"
    }
  },

  subscribeLineImage: {
    position: "relative",
    backgroundPosition: "top center",
    "@media (max-width: 830px)": {
      backgroundPosition: "40%, 100%",
    },
    backgroundSize: "cover",
    "& $container": {
      zIndex: 2,
      position: "relative"
    },
    "& $title": {
      color: whiteColor
    },
    "& $description": {
      color: whiteColor // grayColor[0]
    }
  },
};

export default styles;
