export const englewoodMapData = [
  {
    location: {
      y: 40.8696625,
      x: -73.9582234,
      address: '140 Sylvan Avenue Englewood Cliffs, NJ 07632',
    },
  },
];

export const summitMapData = [
  {
    location: {
      y: 40.71799847698686, 
      x: -74.35657234453963,
      address: '350 Springfield Ave, Suite 200 Summit, NJ 07901',
    },
  },
];


export const collegeData = [
  {
    title: 'BOSTON COLLEGE',
    description: '',
  },
  {
    title: 'BOSTON UNIVERSITY',
    description: '',
  },
  {
    title: 'BROWN UNIVERSITY',
    description: '',
  },
  {
    title: 'CARNEGIE MELLON UNIVERSITY',
    description: '',
  },
  {
    title: 'CASE WESTERN RESERVE UNIVERSITY',
    description: '',
  },
  {
    title: 'CLEVELAND INSTITUTE OF MUSIC',
    description: '',
  },
  {
    title: 'COLUMBIA UNIVERSITY',
    description: '(Rabi Scholar, Egleston Scholar)',
  },
  {
    title: 'CORNELL UNIVERSITY',
    description: '',
  },
  {
    title: 'DARTMOUTH COLLEGE',
    description: '',
  },
  {
    title: 'DUKE UNIVERSITY',
    description: '',
  },
  {
    title: 'EMORY UNIVERSITY',
    description: '',
  },
  {
    title: 'GEORGETOWN UNIVERSITY',
    description: '',
  },
  {
    title: 'GEORGIA INSTITUTE OF TECHNOLOGY',
    description: '',
  },
  {
    title: 'HARVARD UNIVERSITY',
    description: '',
  },
  {
    title: 'JOHNS HOPKINS UNIVERSITY',
    description: '(Hodson Trust Scholar)',
  },
  {
    title: 'MANHATTAN SCHOOL OF MUSIC',
    description: '',
  },
  {
    title: 'NEW YORK UNIVERSITY',
    description: '(Stern School Of Business)',
  },
  {
    title: 'NORTHEASTERN UNIVERSITY',
    description: '',
  },
  {
    title: 'NORTHWESTERN UNIVERSITY',
    description: '',
  },
  {
    title: 'PRINCETON UNIVERSITY',
    description: '',
  },
  {
    title: 'PURDUE UNIVERSITY',
    description: '',
  },
  {
    title: 'RICE UNIVERSITY',
    description: '',
  },
  {
    title: 'RUTGERS UNIVERSITY',
    description: '(School Of Pharmacy)',
  },
  {
    title: 'THE COOPER UNION',
    description: '',
  },
  {
    title: 'UC BERKELEY',
    description: '(University of California, Berkeley)',
  },
  {
    title: 'UCLA',
    description: '(University of California, Los Angeles)',
  },
  {
    title: 'UNIVERSITY OF CHICAGO',
    description: '',
  },
  {
    title: 'UNIVERSITY OF MICHIGAN',
    description: '(Honors College)',
  },
  {
    title: 'UNIVERSITY OF NORTH CAROLINA',
    description: '(Chapel Hill)',
  },
  {
    title: 'UNIVERSITY OF NOTRE DAME',
    description: '',
  },
  {
    title: 'UNIVERSITY OF PENNSYLVANIA',
    description: '(Benjamin Franklin Scholars)',
  },
  {
    title: 'UNIVERSITY OF ROCHESTER',
    description: '',
  },
  {
    title: 'UNIVERSITY OF VIRGINIA',
    description: '',
  },
  {
    title: 'US MILITARY ACADEMY WEST POINT',
    description: '',
  },
  {
    title: 'VANDERBILT UNIVERSITY',
    description: '',
  },
  {
    title: 'WAKE FOREST UNIVERSITY',
    description: '',
  },
  {
    title: 'WASHINGTON UNIVERSITY IN ST. LOUIS',
    description: '',
  },
  {
    title: 'WHARTON M&T',
    description: '',
  },
  {
    title: 'YALE UNIVERSITY',
    description: '',
  },
  {
    title: 'UNIVERSITY OF BRITISH COLUMBIA',
    description: '',
  },
  {
    title: 'IVEY BUSINESS SCHOOL',
    description: '',
  },
  {
    title: 'UNIVERSITY OF TORONTO',
    description: '',
  },
  {
    title: 'UNIVERSITY OF WATERLOO',
    description: '',
  },
  {
    title: 'YORK UNIVERSITY',
    description: '',
  },
];

export const honorsAndAwardsData = [
  {
    title: 'AMC 8, AMC 10, AMC 12, AIME, USAMO',
    description: '',
  },
  {
    title: 'Biology Olympiad',
    description: '',
  },
  {
    title: 'Chemistry Olympiad',
    description: '',
  },
  {
    title: 'Comparative Advantage: The Stanford Economics Journal',
    description: '',
  },
  {
    title: 'Computer Science Olympiad',
    description: '',
  },
  {
    title: 'Congressional Essay Contest',
    description: '',
  },
  {
    title: 'Empirical Methods in Natural Language Processing',
    description: '',
  },
  {
    title: 'FRA Americanism Essay Contest',
    description: '',
  },
  {
    title: 'George S. & Stella M. Knight Essay Contest',
    description: '',
  },
  {
    title: 'Google Tech Challenge',
    description: '',
  },
  {
    title: 'Harvard Economics Review Essay',
    description: '',
  },
  {
    title: 'International Committee of Computational Linguistics',
    description: '',
  },
  {
    title: 'JFK Profile in Courage Essay Contest ',
    description: '',
  },
  {
    title: 'Mathcounts',
    description: '',
  },
  {
    title: 'New York Times Student Competitions',
    description: '',
  },
  {
    title: 'NJCTE High School Writing Contest',
    description: '',
  },
  {
    title: 'Physics Olympiad',
    description: '',
  },
  {
    title: 'Regeneron International Science and Engineering Fair',
    description: '(formally Intel)',
  },
  {
    title: 'Scholastic Art and Writing Awards',
    description: '',
  },
  {
    title: 'The Concord Review',
    description: '',
  },
  {
    title: 'The Conrad Challenge',
    description: '',
  },
  {
    title: 'Voice of Democracy Essay and Scholarship Program',
    description: '',
  },
  {
    title: 'World Series of Innovation',
    description: '',
  },
];

