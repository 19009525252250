import {
  container,
  title,
  main,
  whiteColor,
  blackColor,
  grayColor,
  mainRaised,
} from "assets/jss/material-kit-pro-react.js";

const landingPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    // "@media (max-width: 830px)": {
    //   color: whiteColor,
    // }
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    // color: blackColor,
    color: grayColor[1],
    textDecoration: "none",
    fontSize: "3rem",
    "@media (max-width: 830px)": {
      fontSize: "2rem",
      // color: whiteColor,
      // textShadow: "2px 2px 4px #000000"
    },

    "@media (max-height: 830px)": {
      fontSize: "2rem",
    }
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  description: {
    // color: blackColor,
    color: grayColor[1],

    "@media (max-height: 830px)": {
      fontSize: "1.2rem",
    },

    "@media (max-width: 830px)": {
      // color: whiteColor,
      // textShadow: "2px 2px 5px #ffffff, 2px 2px 20px #0055DD"
    }
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised,
    margin: "0px 30px 0px 30px",
  },
  imgContainer: {
    width: "100%",
    height: "200px",
    // textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    
    "& img": {
      width: "100%",
      height: "350px"
    },

    "@media (min-width: 766px)": {
      height: "280px",
      "& img": {
        width: "100%",
        height: "280px"
      },
    },
    "@media (max-width: 766px)": {
      height: "200px",
      paddingTop: "15px",
      "& img": {
        width: "100%",
        height: "200px"
      },
    }
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
};

export default landingPageStyle;
