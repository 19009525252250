import React from "react";
import Box from '@material-ui/core/Box';
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/ourServicesSections/consultingStyle.js";

import collegeAdmissions from "assets/img/our-service/ourservices_college.png";
import hightSchool from "assets/img/our-service/ourservices_junior.png";
import collegeTransfer from "assets/img/our-service/ourservices_college_1.png";

import Primary from "components/Typography/Primary";
import { infoColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(blogsStyle);


export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const Heading = ({level, children, ...props}) => {
    return React.createElement(`h${level}`, props , children)
  }
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              md={11}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title + " " + classes.textCenter}>
                Consulting Services
              </h2>
              {/* <h3 className={classes.subtitle}> */}
              <Heading level={isSm ? 4 : 3} className={classes.description2}>
                At Insight Academix, we target each part of the application to maximize your chances of admission. 
                Starting as early as 6th grade, we help students develop academic and extracurricular profiles, 
                helping to make their applications competitive. 
                Insight Academix supports college and graduate students as well using the same core strategy, 
                developing students’ potential and readiness for the admission process.
              </Heading>
              <br />

              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <Card consulting>
                    <CardBody consulting>
                      
                      <div className={classes.iconWrapper}
                        style={{
                          backgroundImage: `url(${collegeAdmissions})`,
                          opacity: "1"
                        }}
                      >
                      </div>
                      <h3 style={{textAlign: "center"}}
                        className={
                          classes.cardTitle + " " + classes.marginTop30
                        }
                      >
                        College Admissions
                      </h3>
                      <br />
                      { !isSm && <br /> }
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Building balanced list of reach, target, and safety schools
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Optimizing student profile to create best fit for each college
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Early Admission and Regular Decision strategies
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Advising for Common App
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Editing for essays, resumes, and supplemental materials
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Mock interviews
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Deferral and waitlist strategies
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Support to select final choice
                          </span>
                        </Box>
                      </Box>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <Card consulting>
                    <CardBody consulting>
                      <div className={classes.iconWrapper}
                        style={{
                          backgroundImage: `url(${hightSchool})`,
                          opacity: "1"
                        }}
                      >
                      </div>
                      <h3 style={{textAlign: "center"}}
                        className={
                          classes.cardTitle + " " + classes.marginTop30
                        }
                      >
                        High School
                      </h3>
                      <br />
                      { !isSm && <br /> }
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block">
                          <span className={classes.description2}>
                            Support for private and magnet school admissions
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block">
                          <span className={classes.description2}>
                            SAT, ACT, AP, and IB planning and preparation
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block">
                          <span className={classes.description2}>
                            Course selection and planning
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block">
                          <span className={classes.description2}>
                            Competition search and preparation
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block">
                        <span className={classes.description2}>
                          Research mentorship and publication support
                        </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Developing leadership through extracurricular activities and community service
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Support for competitive summer program applications
                          </span>
                        </Box>
                      </Box>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <Card consulting>
                    <CardBody consulting>
                      
                      <div className={classes.iconWrapper}
                        style={{
                          backgroundImage: `url(${collegeTransfer})`,
                          opacity: "1"
                        }}
                      >
                      </div>
                      <h3 style={{textAlign: "center"}}
                        className={
                          classes.cardTitle + " " + classes.marginTop30
                        }
                      >
                        College Transfer and Graduate School
                      </h3>
                      <br />
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            School selection and timeline management
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Academic advising throughout college
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Research, internship and activity support
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Common App, Coalition App and individual school App support
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Personal statement and supplemental essay support
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            GRE, MCAT, GMAT, LSAT plan and preparation for graduate school applicants
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Medical, Business, and Law school admissions
                          </span>
                        </Box>
                      </Box>
                      <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px'}}>
                        <Box width={30} display="inline-block" >
                          <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                        </Box>
                        <Box width="90%" display="inline-block" >
                          <span className={classes.description2}>
                            Master and PhD programs
                          </span>
                        </Box>
                      </Box>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>

              {/* <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} md={5}>
                    <CardHeader image plain>
                      <img src={cardBlog4} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} md={7}>
                    <Info>
                      <h3 className={classes.cardCategory}>College Admissions Consulting</h3>
                    </Info>
                    <h5 className={classes.cardTitle}>
                      At Insight Academix, we target each part of the application to maximize your chances of admissions.
                    </h5>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Building balanced list of reach, target, and safety schools
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Optimizing student profile to create best fit for each college
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Early Admission and Regular Decision strategies
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Advising for Common App
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Editing for essays, resumes, and supplemental materials
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Mock interviews
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Deferral and waitlist strategies
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                      <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Support to select final choice
                        </span>
                      </Box>
                    </Box>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} md={5}>
                    <CardHeader image plain>
                      <img src={office2} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${office2})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${office2})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} md={7}>
                    <Danger>
                      <h3 className={classes.cardCategory}>
                        Junior & Senior High School Consulting
                      </h3>
                    </Danger>
                    <h5 className={classes.cardTitle}>
                        Starting 6th grade, we help students develop academic careers at school and prepare admissions profiles. 
                    </h5>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block">
                        <span className={classes.description2}>
                          Support for private and magnet school admissions
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block">
                        <span className={classes.description2}>
                          SAT, ACT, AP, IB plan and preparation
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block">
                        <span className={classes.description2}>
                          Course selection and planning
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block">
                        <span className={classes.description2}>
                          Competition search and preparation
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block">
                      <span className={classes.description2}>
                        Research mentorship and publication support
                      </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Developing leadership through extracurricular activities and community service
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Support for competitive summer applications
                        </span>
                      </Box>
                    </Box>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} md={5}>
                    <CardHeader image plain>
                      <img src={blog8} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog8})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog8})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} md={7}>
                    <Success>
                      <h3 className={classes.cardCategory}>College Transfer and Graduate School Consulting</h3>
                    </Success>
                    <h5 className={classes.cardTitle}>
                      Insight Academix supports college students as well. Different timeline and requirements, 
                      but same core strategy, developing students’ potential and readiness for the admission process.
                    </h5>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          School selection and timeline management
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Academic advising throughout college
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Research, internship and activity support
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Common App, Coalition App and individual school App support
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Personal statement and essays support
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          GRE, MCAT, GMAT, LSAT plan and preparation for graduate school applicants
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Medical, Business, and Law schools
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-check' style={{ fontSize: '12px', color: infoColor[0]}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Master and PhD programs
                        </span>
                      </Box>
                    </Box>
                  </GridItem>
                </GridContainer>
              </Card> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
