import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';

import pcImg from "assets/img/our-service/sub_ourservices_joinus.jpg";

import styles from "assets/jss/material-kit-pro-react/views/ourServicesSections/joinUsVirtually.js";

const useStyles = makeStyles(styles);

export default function SectionJoinUsVirtually() {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.section, classes.sectionGray}>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          
        >
          <div className={classes.textCenter}>
            <h2 className={classes.title}>Join Us Virtually</h2>
            <p className={classes.description}>
              We offer virtual consulting, tutoring, and mentoring sessions.
            </p>
          </div>
        </GridItem>
      </GridContainer>

      <br />
      { !isSm && (
        <>
          <br />
        </>
      )}
      <div
        className={classNames(
          classes.subscribeLine,
          classes.subscribeLineImage,
          classes.container
        )}
        style={ { backgroundImage: `url(${pcImg})`} }
        // style={ isSm ? { backgroundImage: `url(${mobileImg})`} : { backgroundImage: `url(${pcImg})`} }
      >
        
      </div>
    </div>
  );
}
