import {
  title,
  mrAuto,
  mlAuto,
  container,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const workStyle = {
  mrAuto,
  mlAuto,
  container: {
    ...container,

    // "@media (min-width: 576px)": {
    //   maxWidth: "960px"
    // },
  },
  section: {
    padding: "70px 0",

    "@media (max-width: 830px)": {
      paddingTop: "0px",
    }
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    "@media (max-width: 830px)": {
      fontSize: "1.7rem",
    }
  },
  description: {
    color: grayColor[4],
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  }
};

export default workStyle;
