import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  whiteColor,
  infoColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const blogsSection = {
  container,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  title: {
    ...title,
    "@media (max-width: 830px)": {
      fontSize: "1.7rem",
    }
  },
  textCenter: {
    textAlign: "center"
  },
  blog: {
    padding: "50px 0",

    "@media (max-width: 830px)": {
      padding: "0px",
    }
  },
  cardCategory: {
    fontWeight: "400",
    marginBottom: "0",
    marginTop: "10px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "8px",
      lineHeight: "0"
    }
  },
  iconWrapper: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid " + grayColor[14],
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    lineHeight: "174px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "55px",
      lineHeight: "55px"
    },
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  iconInfo: {
    color: infoColor[0]
  },
  description1: {
    ...description,
    color: grayColor[4],
    lineHeight: "1.313rem"
  },
  description2: {
    ...description,
    color: grayColor[4],
    lineHeight: "1.813rem"
  },
  author: {
    "& a": {
      color: grayColor[1],
      textDecoration: "none"
    }
  },
  card: {
    marginBottom: "80px",

    "@media (max-width: 830px)": {
      marginBottom: "50px",
    }
  },
  card4: {
    marginBottom: "60px",
    textAlign: "center"
  }
};

export default blogsSection;
