import React from "react";
import { Link } from "react-router-dom";
/*eslint-disable*/ 
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import SectionOurServices from "./Sections/SectionOurServices.js";
import SectionGetInTouch from "./Sections/SectionGetInTouch.js";
import SectionPricing from "./Sections/SectionPricing.js";

import textimg from "assets/img/landing/mainbanner_keyword_object.png";

const useStyles = makeStyles(landingPageStyle);

let locationKey = ""

export default function LandingPage({...rest}) {
  const [headerLinksColor, setHeaderLinksColor] = React.useState("black");

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const isMdDown = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });


  React.useEffect(() => {
    if (rest && rest.location.key !== locationKey) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;

      locationKey = rest.location.key;
    }
  });
  const classes = useStyles();


  const Heading = ({level, children, ...props}) => {
    return React.createElement(`h${level}`, props , children)
  }

  const changeHeaderLinksColor = (color) => {
    setHeaderLinksColor(color);
  }

  return (
    <div>
      <Header
        color="transparent"
        brand="Insight Academix"
        links={<HeaderLinks dropdownHoverColor="info" color={headerLinksColor} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "info",
          changeHeaderLinksColor: changeHeaderLinksColor,
        }}
        {...rest}
      />
      <Parallax image={isMdDown?require("assets/img/landing/mainbanner_keyword_bg.jpg"):require("assets/img/landing/mainbanner_keyword_all.jpg")} filter="dark" opacity={isSm ? 0 : 0}>
        <div className={classes.container}>
        {isMdDown && 
          (<GridContainer>
            <GridItem xs={12} sm={12} lg={6} className={classes.imgContainer}>
              <img src={textimg} alt="..." style={{display: 'block', objectFit: 'cover'}} />
            </GridItem>
          </GridContainer>)
        }
          <GridContainer>
            <GridItem xs={12} sm={12} lg={6}>
              <h1 className={classes.title}>Welcome to Insight Academix</h1>
              <Heading level={isSm ? 4 : 3} className={classes.description}>
                At Insight Academix, we help each student reach their fullest potential in academics, leadership, 
                and character by developing a well-rounded profile. We take into account each students’ strengths, achievements, 
                and goals to build a profile that is unique to them and sets them up for future academic and professional success. 
              <br />
                We are excited to work with you to help you achieve your educational goals!
              </Heading>
              <br />
              <Link to="/about-us#contactUs" title="Contac Us">
                <Button
                  color="info"
                  size="lg"
                  // href="/about-us#contactUs"
                >
                  <i className="fas fa-play" />
                  Contact Us
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        
        </div>
      </Parallax>
    {/* {isMdDown && 
        (<GridContainer>
          <GridItem xs={12} sm={12} md={6} className={classes.imgContainer}>
            <img src={textimg} alt="..." />
          </GridItem>
        </GridContainer>)
    } */}
      
      <div className={classNames(classes.main)}>
          <SectionOurServices />
          {/* <SectionOurStudents />
          <SectionAboutUs />
          <SectionContactUs /> */}
          <div className={classes.container}>
            <SectionGetInTouch />
          </div>
      </div>

      <SectionPricing />

      <Footer theme="dark"
        content={
          <div>
          </div>
        }
      >
        <div></div>
      </Footer>
    </div>
  );
}
