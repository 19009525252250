import React from "react";
// @material-ui/core components
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Gray from "components/Typography/Gray.js";

import blog6 from "assets/img/our-service/sub_ourservices_researchmantoring.jpg";
import blog7 from "assets/img/our-service/sub_ourservices_tutoringprogram.jpg";

import programsStyle from "assets/jss/material-kit-pro-react/views/ourServicesSections/programsStyle.js";

const useStyles = makeStyles(programsStyle);

export default function SectionPrograms() {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const Heading = ({level, children, ...props}) => {
    return React.createElement(`h${level}`, props , children)
  }
  return (
    <div className={classes.section, classes.sectionGray}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Research and Academic Support
            </h2>
            <Heading level={isSm ? 4 : 3} className={classes.description2}>
              Independent research and publications have long been recognized and  appreciated by elite colleges and graduate schools. 
              Insight Academix offers research opportunities in various subjects including STEM and the Humanities, 
              with top notch mentors from prestigious institutions. 
              We also provide support with submitting and publishing at top journals and conferences.
              <br />
              
              High profile tutors are also available to support daily academic needs, as well as standardized tests and competitions.
            </Heading>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card plain>
                  <CardHeader image>
                    <img src={blog6} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog6 + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Gray>
                      <h3 style={{fontWeight: "400"}}>Guided Research Mentoring Program</h3>
                    </Gray>
                    {/* <h5 className={classes.cardTitle}>
                      Independent research achievements have been well appreciated by elite colleges and graduate schools. Insight Academix offers various subjects of research experience with top notch mentors.
                    </h5> */}
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          For High School and College Students.
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Multitude of subjects including Economics, Psychology, Computer Science, and Biology.
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Publication support for journals and conferences.
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Research-based competitions, such as ISEF and STS.
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Mentors from Ivy League colleges and other elite institutions.
                        </span>
                      </Box>
                    </Box>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card plain>
                  <CardHeader image>
                    <img src={blog7} alt="..." />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog7 + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Gray>
                      <h3 style={{fontWeight: "400"}}>
                        Professional Tutoring Program
                      </h3>
                    </Gray>
                    {/* <h5 className={classes.cardTitle}>
                      High profile tutors also available to support daily academic needs, standard tests and competitions.
                    </h5> */}
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          AP and IB classes.
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          SAT, ACT and AP exams.
                        </span>
                      </Box>
                    </Box>
                    <Box width="100%" style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <Box width={30} display="inline-block" >
                        <i className='fas fa-circle' style={{ fontSize: '7px', color: '#999'}} />
                      </Box>
                      <Box width="90%" display="inline-block" >
                        <span className={classes.description2}>
                          Olympiads.
                        </span>
                      </Box>
                    </Box>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
