import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import emailjs from 'emailjs-com';
// @material-ui/icons
import SendIcon from '@material-ui/icons/Send';
import TouchAppIcon from '@material-ui/icons/TouchApp';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import { sendEmail } from "utils/EmailUtility.js"

import getInTouchStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/getInTouchStyle.js";

const useStyles = makeStyles(getInTouchStyle);

export default function SectionGetInTouch() {
  const [formState, setFormState] = React.useState({
    name: '',
    email: '',
    message: ''
  });
  const [emailSnackbarOpen, setEmailSnackbarOpen] = React.useState(false);


  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }))
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(formState);
    emailjs.sendForm('service_0aldcat', 'template_91x5j6s', e.target, 'user_B1Dx6UMDlIhdrIxwSHhnH')
      .then((result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setEmailSnackbarOpen(true);

            // clear form
            setFormState({name: '', email: '', message: ''});
          }
      }, (error) => {
          console.log("##### " + error.text);
      });

      // action="/submition.html?contact-form-submit-success=true"
  }

  const getFormData = (form) => {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements).filter(function(k) {
      if (elements[k].name === "honeypot") {
        honeypot = elements[k].value;
        return false;
      }
      return true;
    }).map(function(k) {
      if(elements[k].name !== undefined) {
        return elements[k].name;
      // special case for Edge's html collection
      }else if(elements[k].length > 0){
        return elements[k].item(0).name;
      }
    }).filter(function(item, pos, self) {
      return self.indexOf(item) == pos && item;
    });

    var formData = {};
    fields.forEach(function(name){
      var element = elements[name];
      
      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail
      = form.dataset.email || ""; // no email by default

    return {data: formData, honeypot: honeypot};
  }

  const sendEmail2 = (event) => {
    event.preventDefault();           // we are submitting via xhr below
    var form = event.target;
    var formData = getFormData(form);
    var data = formData.data;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    // var url = form.action;
    var url = "https://script.google.com/macros/s/AKfycbxHzZAwgPA9y0kgyR-xKajzlGjxFecJf2GZ2ZCGQfneBVyHKE9OETMFxbzdzM_wVYniGw/exec";
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {

          setEmailSnackbarOpen(true);

            // clear form
            setFormState({name: '', email: '', message: ''});
        }
    };
    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');
    xhr.send(encoded);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setEmailSnackbarOpen(false);
  };

  return (
    <div className={classes.section}>
      <Snackbar open={emailSnackbarOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          <AlertTitle>Success</AlertTitle>
            Sent a message!
        </Alert>
      </Snackbar>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={9}>
          <h2 className={classes.title}>Get in touch <SendIcon fontSize="large" /></h2>
          <h4 className={classes.description}>
            Please send us a message and we will get back to you as soon as possible. 
            We look forward to speaking with you.
          </h4>
          <form
          method="post"
          onSubmit={sendEmail2}
          >
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange = {handleChange}
                  inputProps={{
                    name: "name",
                    value: formState.name,
                    // onChange: handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  onChange = {handleChange}
                  inputProps={{
                    name: "email",
                    value: formState.email,
                    // onChange: handleChange
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                onChange = {handleChange}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  name: "message",
                  value: formState.message,
                  // onChange: handleChange
                }}
              />
              <GridItem
                xs={12}
                align="center"
              >
                <Button color="info" type="submit">Submit</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
