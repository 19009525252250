/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import { Provider } from 'react-redux'
import store from './store'
import { icons } from './assets/icons'


import './scss/style.scss';
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import OurServicesPage from "views/OurServicesPage/OurServicesPage.js";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";

React.icons = icons

const Login = React.lazy(() => import('./views/login/Login'));
const Register = React.lazy(() => import('./views/register/Register'));

var hist = createBrowserHistory();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

ReactDOM.render(
  <Provider store={store}>
  <Router history={hist}>
    <React.Suspense fallback={loading}>
      <Switch>
        <Route path="/admin" name="Admin" render={props => <TheLayout {...props}/>} />
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/our-service-page" component={OurServicesPage} />
        <Route path="/about-us" component={AboutUsPage} />
        <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
        <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
        <Route path="/error-page" component={ErrorPage} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </React.Suspense>
  </Router>
  </Provider>,
  document.getElementById("root")
);
