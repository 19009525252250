/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";



const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { children, content, theme, big, className } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <h5>Insight Academix</h5>
                  <p>
                    Insight Academix is a premier academic consulting service with locations in Englewood Cliffs and Summit NJ. {" "}
                  </p>
                  <p>
                    We also have a liaison office in Seoul, South Korea and support a global network of students from countries such as the United States, Canada, South Korea, and Singapore.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <h5>Englewood Cliffs, NJ</h5>
                  <div className={classes.socialFeed}>
                    <div>
                      <p>140 Sylvan Ave, Suite 201, Englewood Cliffs, NJ 07632</p>
                    </div>
                    <div>
                      <p>(201) 468 - 7077</p>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <h5>Summit, NJ</h5>
                  <div className={classes.socialFeed}>
                    <div>
                      <p>350 Springfield Ave, Suite 200, Summit, NJ 07632</p>
                    </div>
                    <div>
                      <p>(908) 271 - 8667</p>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <hr />
          </div>
        ) : (
          " "
        )}
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/" className={classes.block}>
                  Insight Academix
                  </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/our-service-page" className={classes.block}>
                  Our Services
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/about-us#ourStudents" className={classes.block}>
                  Our Students
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/about-us" className={classes.block}>
                  About us
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/about-us#contactUs" className={classes.block}>
                  Contact Us
                </Link>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            {/* &copy; {1900 + new Date().getYear()} , made with{" "}
            by{" "} Insight Academix Team  */}
            &copy; Since 2014
          </div>
        </div>
        {content}
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  content: PropTypes.node.isRequired
};
