/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import { useLocation } from "react-router-dom";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionAboutUs from "views/AboutUsPage/Sections/SectionAboutUs.js";
import SectionCollege from "views/AboutUsPage/Sections/SectionCollege.js";
import SectionContactUs from "views/AboutUsPage/Sections/SectionContactUs.js";
import SectionPricing from "views/AboutUsPage/Sections/SectionPricing.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

import { collegeData, honorsAndAwardsData, englewoodMapData, summitMapData} from './data';

const useStyles = makeStyles(aboutUsStyle);

let locationKey = ""

export default function AboutUsPage(rest) {
  let location = useLocation();
  const [headerLinksColor, setHeaderLinksColor] = React.useState("black");

  React.useEffect(() => {
    if (rest && rest.location.key !== locationKey) {
      var href = window.location.href.substring(
        window.location.href.lastIndexOf("#") + 1
      );
      if (window.location.href.lastIndexOf("#") > 0) {
        document.getElementById(href).scrollIntoView();
        // targetRef.current.scrollIntoView();
      } else {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }

      locationKey = rest.location.key;
    }
  });
  // }, [window.location.href]);
  

  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const Heading = ({level, children, ...props}) => {
    return React.createElement(`h${level}`, props , children)
  }

  const changeHeaderLinksColor = (color) => {
    setHeaderLinksColor(color);
  }

  return (
    <div>
      <Header
        brand="Insight Academix"
        links={<HeaderLinks dropdownHoverColor="info" color={headerLinksColor} />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info",
          changeHeaderLinksColor: changeHeaderLinksColor,
        }}
      />
      <Parallax image={require("assets/img/about-us/sub_aboutus_banner.jpg")} filter="dark" opacity={0} small={isSm ? false : true}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={4}
              sm={8}
              className={classNames(
                // classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>About Us</h1>
              <Heading level={isSm ? 4 : 3} className={classes.subtitle}>
                Insight Academix is a premier academic consulting service with locations in Englewood Cliffs and Summit NJ. 
                We also have a liaison office in Seoul, South Korea and support a global network of students from countries 
                such as the United States, Canada, South Korea, and Singapore.
              </Heading>

              {isSm && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionAboutUs />
          <div id="ourStudents">
            <SectionCollege collegeData={collegeData} honorsAndAwardsData={honorsAndAwardsData} />
          </div>
          <div id="contactUs">
            <SectionContactUs englewoodMapData={englewoodMapData} summitMapData={summitMapData} />
          </div>

        </div>
      </div>
      <SectionPricing />
      <Footer theme="dark"
        content={
          <div>
          </div>
        }
      >
        <div></div>
      </Footer>
    </div>
  );
}
