import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
import clsx from 'clsx';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeroSideImage from "components/HeroSideImage/HeroSideImage";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from '@material-ui/core';
// @material-ui/icons
import Subject from "@material-ui/icons/Subject";

import ourServicesStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/ourServicesStyle.js";

import ourServicesImage from "assets/img/landing/main_ourservices.png";
import ourStudentImage from "assets/img/landing/main_ourstudents.png";
import aboutUsImage from "assets/img/landing/main_aboutus_1.png";
import contactUsImage from "assets/img/landing/main_contactus.png";

const useStyles = makeStyles(ourServicesStyle);


export default function SectionOurStudents() {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const Heading = ({level, children, ...props}) => {
    return React.createElement(`h${level}`, props , children)
  }

  return (
    <div className={classNames(classes.section)}>
      <HeroSideImage
        imageSrc={ourServicesImage}
        imageSrcSet={ourServicesImage}
        reverse={0 % 2 === 0}
        key={0}
      >
        <Grid container spacing={2} className={classes.textContainer}>
          <Grid item xs={12}>
            <h2 className={classes.title}>Our Services</h2>
          </Grid>
          <Grid item xs={12}>
              <Heading level={isSm ? 4 : 3} className={classes.description} >
                Insight Academix serves middle school, high school, and college students for 
                their immediate academic needs and next level application processes.
              </Heading>
          </Grid>
          <Grid item xs={12}>
            <Link to="/our-service-page" title="Our Services">
              <Button color="info" round>
                <Subject className={classes.icons} /> Learn more
              </Button>
            </Link>
          </Grid>
        </Grid>
      </HeroSideImage>
      <HeroSideImage className={classes.sectionInfo}
        imageSrc={ourStudentImage}
        imageSrcSet={ourStudentImage}
        reverse={1 % 2 === 0}
        key={1}
      >
        <Grid container spacing={2} className={classes.textContainer}>
          <Grid item xs={12}>
            <h2 className={classes.title}>Our Students</h2>
          </Grid>
          <Grid item xs={12}>
            <Heading level={isSm ? 4 : 3} className={classes.description} >
                Students have been accepted from Ivy League and other top colleges every year with 
                Insight Academix college bound consulting services. 
                They have also published research papers and abstracts at professional conferences and 
                received numerous awards from top STEM and humanities competitions.
              </Heading>
          </Grid>
          <Grid item xs={12}>
            <Link to="/about-us#ourStudents" title="Our Students">
              <Button color="info" round >
                <Subject className={classes.icons} /> Learn more
              </Button>
            </Link>
          </Grid>
        </Grid>
      </HeroSideImage>
      <HeroSideImage
        imageSrc={aboutUsImage}
        imageSrcSet={aboutUsImage}
        // objectFit="contain"
        reverse={2 % 2 === 0}
        key={2}
      >
        <Grid container spacing={2} className={classes.textContainer}>
          <Grid item xs={12}>
            <h2 className={classes.title}>About Us</h2>
          </Grid>
          <Grid item xs={12}>
              <Heading level={isSm ? 4 : 3} className={classes.description} >
                Insight Academix is a team of experienced educational professionals including academic advisors, 
                college admission officers, high school teachers, college professors, and independent specialists. 
                All of us are ready and waiting to support our students.
              </Heading>
          </Grid>
          <Grid item xs={12}>
            <Link to="/about-us" title="About us">
              <Button color="info" round>
                <Subject className={classes.icons} /> Learn more
              </Button>
            </Link>
          </Grid>
        </Grid>
      </HeroSideImage>
      <HeroSideImage className={classes.sectionInfo}
        imageSrc={contactUsImage}
        imageSrcSet={contactUsImage}
        reverse={3 % 2 === 0}
        key={3}
      >
        <Grid container spacing={2} className={classes.textContainer}>
          <Grid item xs={12}>
            <h2 className={classes.title}>Contact Us</h2>
          </Grid>
          <Grid item xs={12}>
            <Heading level={isSm ? 4 : 3} className={classes.description} >
              Insight Academix has convenient locations in Englewood Cliffs and Summit  New Jersey, 
              as well as in Seoul, South Korea. We also work with students remotely through virtual meetings.
            </Heading>
          </Grid>
          <Grid item xs={12}>
            <Link to="/about-us#contactUs" title="Contact Us">
              <Button color="info" round >
                <Subject className={classes.icons} /> Learn more
              </Button>
            </Link>
          </Grid>
        </Grid>
      </HeroSideImage>
    </div>
  );
}
