import {
  container,
  title,
  main,
  mainRaised,
  mlAuto,
  grayColor,
  description
} from "assets/jss/material-kit-pro-react.js";

const contactUsStyle = {
  main,
  mainRaised,
  title: {
    ...title,
    "@media (max-width: 830px)": {
      fontSize: "1.5rem",
    }
  },
  mlAuto,
  description: {
    ...description,
    color: grayColor[4],
  },
  container: {
    ...container,
    maxWidth: "1270px !important"
  },
  contactContent: {
    paddingBottom: "40px",
    paddingTop: "40px"
  },
  bigMap: {
    height: "45vh",
    maxHeight: "550px",
    width: "100%",
    display: "block"
  },
  smallMap: {
    position: 'relative',
    height: "45vh",
    maxHeight: "550px",
    width: "100%",
    display: "block",

    "@media (max-width: 830px)": {
      // width: '90%',
      height: "30vh",
    },
    "@media (min-width: 1600px)": {
      height: "35vh",
    },
  },
  info: {
    paddingBottom: "10px",
    paddingTop: 0
  },
  textCenter: {
    textAlign: "center !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
};

export default contactUsStyle;
