/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import emailjs from 'emailjs-com';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Email from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Map from "components/Map/Map.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

// const useStyles = makeStyles((theme) => ({
//   map: {
//     zIndex: 9,
//   },
// }));

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage(props) {
  const { englewoodMapData, summitMapData, ...rest } = props;
  const [formState, setFormState] = React.useState({
    name: '',
    email: '',
    message: ''
  });
  const [emailSnackbarOpen, setEmailSnackbarOpen] = React.useState(false);

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });
  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }))
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(formState);
    emailjs.sendForm('service_0aldcat', 'template_91x5j6s', e.target, 'user_B1Dx6UMDlIhdrIxwSHhnH')
      .then((result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setEmailSnackbarOpen(true);

            // clear form
            setFormState({name: '', email: '', message: ''});
          }
      }, (error) => {
          console.log("##### " + error.text);
      });

      // action="/submition.html?contact-form-submit-success=true"
  }

  const getFormData = (form) => {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements).filter(function(k) {
      if (elements[k].name === "honeypot") {
        honeypot = elements[k].value;
        return false;
      }
      return true;
    }).map(function(k) {
      if(elements[k].name !== undefined) {
        return elements[k].name;
      // special case for Edge's html collection
      }else if(elements[k].length > 0){
        return elements[k].item(0).name;
      }
    }).filter(function(item, pos, self) {
      return self.indexOf(item) == pos && item;
    });

    var formData = {};
    fields.forEach(function(name){
      var element = elements[name];
      
      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail
      = form.dataset.email || ""; // no email by default

    return {data: formData, honeypot: honeypot};
  }

  const sendEmail2 = (event) => {
    event.preventDefault();           // we are submitting via xhr below
    var form = event.target;
    var formData = getFormData(form);
    var data = formData.data;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    // var url = form.action;
    var url = "https://script.google.com/macros/s/AKfycbxHzZAwgPA9y0kgyR-xKajzlGjxFecJf2GZ2ZCGQfneBVyHKE9OETMFxbzdzM_wVYniGw/exec";
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {

          setEmailSnackbarOpen(true);

            // clear form
            setFormState({name: '', email: '', message: ''});
        }
    };
    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');
    xhr.send(encoded);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setEmailSnackbarOpen(false);
  };

  return (
    <div>
      <Snackbar open={emailSnackbarOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          <AlertTitle>Success</AlertTitle>
            Sent a message!
        </Alert>
      </Snackbar>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} key={1} data-aos="fade-up">
          <div className={classes.smallMap}>
            <Map
              zoom = {13}
              center={[40.8696625, -73.9582234]}
              pins={englewoodMapData}
              // className={classes.map}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} key={2} data-aos="fade-up">
        <div className={classes.smallMap}>
            <Map
              zoom = {13}
              center={[40.71799847698686, -74.35657234453963]}
              pins={summitMapData}
              // className={classes.map}
            />
        </div>
        </Grid>
      </Grid>
      <div className={classNames(classes.main)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Send Us a Message <MailOutlineIcon fontSize="large" /></h2>
            <GridContainer>
              <GridItem md={5} sm={12}>
                <p className={classes.description}>
                  Please send us a message and we will get back to you as soon as possible. 
                  We look forward to speaking with you.
                </p>
                <br />
                <form
                  method="post"
                  onSubmit={sendEmail2}
                >
                  <CustomInput
                    labelText="Your Name"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange = {handleChange}
                    inputProps={{
                      name: "name",
                      value: formState.name,
                    }}
                  />
                  <CustomInput
                    labelText="Your email address"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange = {handleChange}
                    inputProps={{
                      name: "email",
                      value: formState.email,
                    }}
                  />
                  {/* <CustomInput
                    labelText="Your phone"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  /> */}
                  <CustomInput
                    labelText="Message"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange = {handleChange}
                    inputProps={{
                      name: "message",
                      value: formState.message,
                      multiline: true,
                      rows: 6
                    }}
                  />
                  <div className={classes.textCenter}>
                    <Button color="info" type="submit" round>
                      Submit
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={6} sm={12} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Englewood Cliffs, NJ"
                  description={
                    <div>
                      <Typography variant="body1" color="inherit" style={{color: "#3C4858", fontSize: "19px", fontWeight: "300"}}>
                        · 140 Sylvan Ave, Suite 201, Englewood Cliffs, NJ, 07632
                      </Typography>
                      <Typography variant="body1" color="inherit" style={{color: "#3C4858", fontSize: "19px", fontWeight: "300"}}
                        component="a" href="tel:201-468-7077">
                        · (201) 468 - 7077
                      </Typography>
                    </div>
                  }
                  icon={PinDrop}
                  iconColor="info"
                />
                <InfoArea
                  className={classes.info}
                  title="Summit, NJ"
                  description={
                    <div>
                      <Typography variant="body1" color="inherit" style={{color: "#3C4858", fontSize: "19px", fontWeight: "300"}}>
                        · 350 Springfield Ave, Suite 200, Summit NJ, 07901
                      </Typography>
                      <Typography variant="body1" color="inherit" style={{color: "#3C4858", fontSize: "19px", fontWeight: "300"}}
                        component="a" href="tel:908-271-8667">
                        · (908) 271 - 8667
                      </Typography>
                    </div>
                  }
                  icon={PinDrop}
                  iconColor="info"
                />
                {/* <InfoArea
                  className={classes.info}
                  title="Email"
                  description={
                    <Typography variant="body1" color="inherit" style={{color: "#3C4858",fontWeight: "300"}}>
                      Insight Academix. <br /> contact@insightacademix.com
                    </Typography>
                  }
                  icon={Email}
                  iconColor="info"
                /> */}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
