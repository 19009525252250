import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/aboutUsStyle.js";

import aboutus from "assets/img/about-us/sub_about_guidingstudents.png";


const useStyles = makeStyles(aboutUsStyle);

export default function SectionAboutUs({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features3}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.imgContainer}>
                <img src={aboutus} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                // icon={AdjustIcon}
                title="Guiding Students to Success"
                description={
                  <p>
                    Insight Academix has established a solid reputation for preparing students in the undergraduate 
                    and graduate admissions process. 
                    We specialize in strategic school selection and essay editing, 
                    with a focus on developing students’ intellectual and leadership skills. 
                    We provide resources and programs to help you make your profile as competitive as possible.
                  </p>
                }
                iconColor="primary"
              />
              <InfoArea
                className={classes.infoArea}
                // icon={AdjustIcon}
                title="The Insight Academix Team"
                description={
                  <p>
                    At Insight Academix, educational experts, advisors, mentors, and teachers work together to 
                    build personalized academic and admissions strategies for each student. 
                    We highly value personal attention and collaboration throughout the consulting process. 
                    We are excited to design a plan that helps students succeed in academics, extracurricular activities, and beyond.
                  </p>
                }
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
