import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import collegeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/collegeStyle.js";

import map from "assets/img/about-us/map.png";
import winnerTrophy from "assets/img/about-us/sub_about_trophy.png";


const useStyles = makeStyles(collegeStyle);

export default function SectionCollege(props) {
  const { collegeData, honorsAndAwardsData, ...rest } = props;

  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Our Students</h2>
          <h5 className={classes.description}>
            Our students have been accepted by Ivy League colleges and other reputable schools 
            throughout the United States and Canada since 2014.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={12} sm={12} xs={12}>
          <div className={classes.imgContainer}>
            <img src={map} alt="..." />
          </div>
        </GridItem>
      </GridContainer>

      <br />
      <GridContainer>
    { collegeData.map((item, index) => (
        <GridItem key={index} md={3} sm={6} xs={12}>
            <Card college plain >
              {/* <CardAvatar profileSquare image={item.imgsrc}> */}
                {/* <img src={item.imgsrc} alt="profile-pic" className={classes.img} /> */}
              {/* </CardAvatar> */}
              <CardBody college>
                <h4 className={classes.cardTitle}>{item.title}</h4>
                <p className={classes.cardDescription}>
                  {item.description}
                </p>
              </CardBody>
            </Card>
          </GridItem>
    ))}
      </GridContainer>

      <br />
      <br />

      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Honors and Awards</h2>
          <h5 className={classes.description}>
            Our students have achieved numerous awards from academic competitions and 
            have published their research in high profile journals and 
            conferences in the United States and globally.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem md={12} sm={12} xs={12}>
          <div className={classes.imgContainer2}>
            <img src={winnerTrophy} alt="..." />
          </div>
        </GridItem>
      </GridContainer>

      <br />
      <GridContainer>

      { honorsAndAwardsData.map((item, index) => (
        <GridItem key={index} md={3} sm={6} xs={12}>
            <Card college plain >
              {/* <CardAvatar profileSquare image={item.imgsrc}> */}
                {/* <img src={item.imgsrc} alt="profile-pic" className={classes.img} /> */}
              {/* </CardAvatar> */}
              <CardBody college>
                <h4 className={classes.cardTitle}>{item.title}</h4>
                <p className={classes.cardDescription}>
                  {item.description}
                </p>
              </CardBody>
            </Card>
          </GridItem>
    ))}
        {/* <GridItem md={3} sm={6} xs={6}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img src={FaceMarc} alt="profile-pic" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>BOSTON COLLEGE</h4>
              <p className={classes.cardDescription}>
                
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceKendall}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>BOSTON UNIVERSITY</h4>
              <p className={classes.cardDescription}>
                
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceChristian}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>CARNEGIE MELLON UNIVERSITY</h4>
              <p className={classes.cardDescription}>
                
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceAvatar}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>CASE WESTERN RESERVE UNIVERSITY</h4>
              <p className={classes.cardDescription}>
                
              </p>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
